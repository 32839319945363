<template>
  <div>
    <ListLayout
      ref="list"
      :headers="headers"
      :title="title"
      :noFilter="noFilter"
      @fetch="init"
      @showMultipleActions="showMultipleActions = $event"
    >
      <template v-slot:multipleActions>
        <DevisClientActions
          :multiple="true"
          :object="$refs.list ? $refs.list.itemsSelected : []"
          size="xl"
          v-show="showMultipleActions"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
      <template v-slot:customFilters>
        <v-checkbox
          label="Attaché à une facture"
          v-model="customFilters.isAttached"
        />
        <ClientSelector :addAction="null" v-model="customFilters.client" />
      </template>
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
      <template v-slot:[`item.object`]="{ item }">
        <DevisClientObject :devisClientProp="item" size="xs" />
      </template>
      <template v-slot:[`item.dateCreation`]="{ item }">
        {{ item.dateCreation | shortDateFR }}
      </template>
      <template v-slot:[`item.client`]="{ item }">
        <ClientObject
          :clientID="item.client.id"
          size="xs"
          action="popup"
          v-if="item.client.id"
        />
      </template>

      <template v-slot:[`item.montantHT`]="{ item }">
        <MoneyLayout :montant="item.montantHT" />
      </template>
      <template v-slot:[`item.montantTVA`]="{ item }">
        <MoneyLayout :montant="item.montantTVA" />
      </template>
      <template v-slot:[`item.factureAssociee`]="{ item }">
        <FactureClientObject 
          v-if="item.factureAssociee && item.factureAssociee.id"
          size="xs"
          :factureClientID="item.factureAssociee.id"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <DevisClientActions
          :object="item"
          size="xs"
          @fetch="init"
          @loading="$refs.list.loading = true"
        />
      </template>
    </ListLayout>
  </div>
</template>

<script>
import { QUERY_devisClients } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ClientSelector from "./ClientSelector.vue";
import DevisClientActions from "./DevisClientActions.vue";
import DevisClientObject from "./DevisClientObject.vue";
import FactureClientObject from "./FactureClientObject.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "DevisClientsList",

  components: {
    ClientObject,
    ClientSelector,
    DevisClientActions,
    DevisClientObject,
    FactureClientObject,
    ListLayout,
    MoneyLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default: "Liste des devis",
    },
  },

  computed: {
    headers()  {
      let hdrs = [
        { text: "", value: "object", sortable: false },
        { text: "Numero de devis", value: "numeroDevis" },
        { text: "Date de création", value: "dateCreation" },
        { text: "Client", value: "client", sortable: false },
        { text: "Montant HT", value: "montantHT" },
        { text: "Montant TVA", value: "montantTVA" },
        { text: "Fichiers", value: "fichiers", sortable: false },
        { text: "Commentaires", value: "commentaires", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ]

      if(this.customFilters.isAttached) hdrs.splice(6, 0, { text: "Facture associée", value: "factureAssociee", sortable: false })

      return hdrs;
    }
  },

  data: () => ({
    customFilters: {
      client: null,
      isAttached: false
    },
    showMultipleActions: false,
  }),

  methods: {
    fetchDevisClients() {
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));
      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }
      if (this.customFilters.client) {
        pagination.filter.customSearch.push({
          client: this.customFilters.client,
        });
      }

      pagination.filter.customSearch.push({ factureAssociee: this.customFilters.isAttached ? { $ne: null } : null })

      this.$apollo
        .query({
          query: QUERY_devisClients,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
          const data = __.data;
          this.$refs.list.items = data.devisClients;
          this.$refs.list.itemsCount = data.devisClientsCount;
          this.$refs.list.loading = false;
        });
    },
    init() {
      this.fetchDevisClients();
    },
  },

  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>
