import gql from 'graphql-tag';
import {
  AFFAIRE_FIELDS,
  BAILLEUR_FIELDS,
  CONTRAT_LOCATION_FIELDS,
  DEMANDE_ACCORD_FIELDS,
  DEMANDE_PARTENAIRE_FIELDS,
  ENVELOPPE_THEORIQUE_FIELDS,
  FINANCEMENT_FIELDS,
  PARTENAIRE_FIELDS
} from './fragments';

import {
  ENSEIGNE_FIELDS,
  ENTREPRISE_FIELDS,
  ETABLISSEMENT_FIELDS,
  PERSONNE_FIELDS
} from '@/components/COMPONENTS-annuaire/graphql/fragments'

import {
  CLIENT_FIELDS,
  DEVIS_FOURNISSEUR_FIELDS,
  DEVIS_FOURNISSEUR_LIGNE_FIELDS,
  FOURNISSEUR_FIELDS,
  MOYENPAIEMENT_FIELDS,
  FACTURE_FOURNISSEUR_FIELDS,
  FACTURE_FOURNISSEUR_LIGNE_FIELDS
} from '@/components/COMPONENTS-devis-facture/graphql/fragments';
import { USER_FIELDS } from '@/components/COMPONENTS-authentification-habilitation/graphql/fragments'
import {
  ASSET_FIELDS,
  MARQUE_FIELDS,
  MODELE_FIELDS
} from '@/components/COMPONENTS-assets/graphql/fragments';

export const QUERY_affaire = gql`
query($id:ID!){
  affaire(id:$id){
    ...AffaireFields
    commissionEntreprises{
      ...EntrepriseFields
    }
    commissionPersonnes{
      ...PersonneFields
    }
    contratLocation{
      id
    }
    createdBy{
        ...UserFields
    }
    demandeAccords{
      ...DemandeAccordFields
    }
    devisFournisseurs{
      ...DevisFournisseurFields
      client{
        ...ClientFields
      }
      fournisseur{
        ...FournisseurFields
      }      
    }
    facturesFournisseurs{
      ...FactureFournisseurFields
      fournisseur{
        ...FournisseurFields
      }
      client{
        ...ClientFields
      }
    }
    financement{
      ...FinancementFields
      repartition{
        client{
          id
        }
        partCapital
        montantTVA
      }
    }
    locataire{
      ...ClientFields
      entreprise {
        ...EntrepriseFields
      }
    }  
    signataireClient{
      ...PersonneFields
      associatedUser{
      ...UserFields
      }
    }    
 

  }
}
${AFFAIRE_FIELDS}
${CLIENT_FIELDS}
${DEMANDE_ACCORD_FIELDS}
${DEVIS_FOURNISSEUR_FIELDS}
${ENTREPRISE_FIELDS}
${FACTURE_FOURNISSEUR_FIELDS}
${FINANCEMENT_FIELDS}
${FOURNISSEUR_FIELDS}
${PERSONNE_FIELDS}
${USER_FIELDS}
`

export const QUERY_affaires = gql`
query($pagination: PaginationObject){
  affaires(pagination: $pagination){
    ...AffaireFields
    contratLocation{
      id
    }
    demandeAccords{
      ...DemandeAccordFields
    }
    financement{
      ...FinancementFields
      repartition{
        client{
          id
        }
        partCapital
        montantTVA
      }
    }
    locataire{
      ...ClientFields
      entreprise {
        ...EntrepriseFields
      }
    }
  }
  affairesCount(pagination: $pagination)
}${AFFAIRE_FIELDS}
${CLIENT_FIELDS}
${DEMANDE_ACCORD_FIELDS}
${ENTREPRISE_FIELDS}
${FINANCEMENT_FIELDS}
`;

export const QUERY_affairesCount = gql`
query($pagination: PaginationObject){
  affairesCount(pagination:$pagination)
}
`;

export const QUERY_assetsInfosFromEtb = gql`
  query($idEtb: ID!) {
    assetsInfosFromEtb(idEtb: $idEtb)
  }
`;

export const QUERY_bailleur = gql`
  query($id:ID!){
    bailleur(id:$id){
      ...BailleurFields
      createdBy{
        ...UserFields
      }
      informationCreancier{
        raisonSociale
        adresse
        cpVille
        pays
        ics
      }
    entrepriseAssociee{
      ...EntrepriseFields
    }
    }
  }${BAILLEUR_FIELDS}
  ${ENTREPRISE_FIELDS}
  ${USER_FIELDS}
`;

export const QUERY_bailleurs = gql`
query($pagination: PaginationObject){
  bailleurs(pagination: $pagination){
    ...BailleurFields
    entrepriseAssociee{
      ...EntrepriseFields
    }
  }
  bailleursCount(pagination: $pagination)
}${BAILLEUR_FIELDS}
${ENTREPRISE_FIELDS}
`;

export const QUERY_bailleursCount = gql`
query($pagination: PaginationObject){
  bailleursCount(pagination:$pagination)
}
`;

export const QUERY_contratLocation = gql`
query($id:ID!){
  contratLocation(id:$id){
    ...ContratLocationFields
    affaire{
      id
    }
    bailleur{
      ...BailleurFields
      entrepriseAssociee{
        ...EntrepriseFields
      }
    }
    commissionEntreprises{
      ...EntrepriseFields
    }
    commissionPersonnes{
      ...PersonneFields
    }
    createdBy{
        ...UserFields
    }
    demandeAccord{
      id
    }
    devisFournisseurs{
      ...DevisFournisseurFields
      fournisseur{
        ...FournisseurFields
      }
      client{
        ...ClientFields
        etablissement{
          ...EtablissementFields
          enseigne {
            ...EnseigneFields
          }
          entreprise{
            ...EntrepriseFields
          }
        }
      }
      lignes{
        ...DevisFournisseurLigneFields
        modele{
          ...ModeleFields
          marque{
            ...MarqueFields
          }
        }
      }
    }
    facturation{
      type
      repartition{
        client{
          ...ClientFields
          etablissement{
            ...EtablissementFields
            enseigne {
              ...EnseigneFields
            }
            entreprise{
              ...EntrepriseFields
            }
          }
        }
        pointFacturation{
          id
        }
        moyenPaiement{
          ...MoyenPaiementFields
        }
        tauxFacturation
        garantieBailleur
        prelevementPourCompte

      }
    }
    facturesFournisseurs{
      ...FactureFournisseurFields
      fournisseur{
        ...FournisseurFields
      }
      client{
        ...ClientFields
          etablissement{
            ...EtablissementFields
            enseigne {
              ...EnseigneFields
            }
            entreprise{
              ...EntrepriseFields
            }
          }
      }
      lignes{
        ...FactureFournisseurLigneFields
        equipements{
          ...AssetFields
          modele{
            ...ModeleFields
            marque{
              ...MarqueFields
            }
          }
        }
      }
    }
    financement{
      ...FinancementFields
      repartition{
        client{
          ...ClientFields
          etablissement{
            ...EtablissementFields
            enseigne {
              ...EnseigneFields
            }
            entreprise{
              ...EntrepriseFields
            }
          }
        }
        partCapital
        montantTVA
      }
    }
    locataire{
      ...ClientFields
      etablissement{
        ...EtablissementFields
      }
      entreprise {
        ...EntrepriseFields
      }
    }
    signataireClient{
      ...PersonneFields
      fonctions{
      fonction
      pouvoirSignature
      etablissement{
        ...EtablissementFields
        entreprise{
          ...EntrepriseFields
        }
      }
    }
      associatedUser{
      ...UserFields
      }
    }
  }
}
${ASSET_FIELDS}
${BAILLEUR_FIELDS}
${CLIENT_FIELDS}
${CONTRAT_LOCATION_FIELDS}
${DEVIS_FOURNISSEUR_FIELDS}
${DEVIS_FOURNISSEUR_LIGNE_FIELDS}
${ENSEIGNE_FIELDS}
${ENTREPRISE_FIELDS}
${ETABLISSEMENT_FIELDS}
${FACTURE_FOURNISSEUR_FIELDS}
${FACTURE_FOURNISSEUR_LIGNE_FIELDS}
${FINANCEMENT_FIELDS}
${FOURNISSEUR_FIELDS}
${MARQUE_FIELDS}
${MODELE_FIELDS}
${MOYENPAIEMENT_FIELDS}
${PERSONNE_FIELDS}
${USER_FIELDS}
`
export const QUERY_contratLocations = gql`
query($pagination: PaginationObject){
  contratLocations(pagination: $pagination){
    ...ContratLocationFields
    bailleur{
      id
    }
    createdBy{
        ...UserFields
    }
    commissionEntreprises{
      ...EntrepriseFields
    }
    commissionPersonnes{
      ...PersonneFields
    }
    demandeAccord{
      id
    }
    devisFournisseurs{
      ...DevisFournisseurFields
      client{
        ...ClientFields
      }
      fournisseur{
        ...FournisseurFields
      }     
    }
    locataire{
      ...ClientFields
    } 
    
    signataireClient{
      ...PersonneFields
      associatedUser{
      ...UserFields
      }
    }
   
    financement{
      ...FinancementFields
      repartition{
        client{
          id
        }
        partCapital
        montantTVA
      }
    }
  }
  contratLocationsCount(pagination: $pagination)
}
${CLIENT_FIELDS}
${CONTRAT_LOCATION_FIELDS}
${DEVIS_FOURNISSEUR_FIELDS}
${ENTREPRISE_FIELDS}
${FINANCEMENT_FIELDS}
${FOURNISSEUR_FIELDS}
${PERSONNE_FIELDS}
${USER_FIELDS}
`;

export const QUERY_contratLocationsCount = gql`
query($pagination: PaginationObject){
  contratLocationsCount(pagination:$pagination)
}
`;

export const QUERY_demandeAccord = gql`
  query($id:ID!){
    demandeAccord(id:$id){
      createdBy{
        ...UserFields
      }
      affaire{
        ...AffaireFields
        locataire{
         ...ClientFields
        } 
      }
      bailleur{
        ...BailleurFields
        entrepriseAssociee{
          ...EntrepriseFields
        }
      }
      ...DemandeAccordFields
    }
  }${AFFAIRE_FIELDS}
  ${BAILLEUR_FIELDS}
  ${CLIENT_FIELDS}
  ${DEMANDE_ACCORD_FIELDS}
  ${ENTREPRISE_FIELDS}
  ${USER_FIELDS}
`;

export const QUERY_contratLocationDateDernierContrat = gql`
query{
  contratLocationDateDernierContrat
}
`;

export const QUERY_demandeAccords = gql`
  query($pagination: PaginationObject){
    demandeAccords(pagination: $pagination){
      affaire{
        ...AffaireFields
        locataire{
          ...ClientFields
          
        }  
      }
      bailleur{
        ...BailleurFields
        entrepriseAssociee{
          ...EntrepriseFields
        }
      }      
      ...DemandeAccordFields
    }
    demandeAccordsCount(pagination: $pagination)
  }${AFFAIRE_FIELDS}
  ${BAILLEUR_FIELDS}
  ${CLIENT_FIELDS}
  ${DEMANDE_ACCORD_FIELDS}
  ${ENTREPRISE_FIELDS}

`;

export const QUERY_contratLocationsFromAssets = gql`
  query($ids: [String]) {
    contratLocationsFromAssets(ids: $ids)
  }
`;

export const QUERY_demandeAccordsCount = gql`
query($pagination: PaginationObject){
  demandeAccordsCount(pagination:$pagination)
}
`;

export const QUERY_demandePartenaire = gql`
  query($id: ID!) {
    demandePartenaire(id: $id) {
      ...DemandePartenaireFields
    }
  }${DEMANDE_PARTENAIRE_FIELDS}
`;

export const QUERY_demandePartenaires = gql`
  query($pagination: PaginationObject) {
    demandePartenaires(pagination: $pagination) {
      ...DemandePartenaireFields
    }
    demandePartenairesCount(pagination: $pagination)
  }${DEMANDE_PARTENAIRE_FIELDS}
`;

export const QUERY_demandePartenairesCount = gql`
query($pagination: PaginationObject){
  demandePartenairesCount(pagination:$pagination)
}
`;

export const QUERY_enveloppeTheorique = gql`
  query($id: ID!) {
    enveloppeTheorique(id: $id) {
      ...EnveloppeTheoriqueFields
    }
  }${ENVELOPPE_THEORIQUE_FIELDS}
`;

export const QUERY_enveloppeTheoriques = gql`
  query($pagination: PaginationObject) {
    enveloppeTheoriques(pagination: $pagination) {
      ...EnveloppeTheoriqueFields
    }
    enveloppeTheoriquesCount(pagination: $pagination)
  }${ENVELOPPE_THEORIQUE_FIELDS}
`;

export const QUERY_enveloppeTheoriquesCount = gql`
query($pagination: PaginationObject){
  enveloppeTheoriquesCount(pagination:$pagination)
}
`;

export const QUERY_demandeFournisseursCount = gql`
query($pagination: PaginationObject){
  demandeFournisseursCount(pagination:$pagination)
}
`;

export const QUERY_projetSateLeaseOrganisationParametres = gql`
query{
  projetSateLeaseOrganisationParametres{
    id
    affaireFinancementDefaultRate
    affaireFinancementDefaultNpPrincipales
    affaireFinancementDefaultNpComplementaires
    affaireFinancementDefaultFV
    affaireFinancementDefaultIsBeginning
    affaireFinancementDefaultCf
    contratLocationSignataireLoueur
    fraisDossierPourcentage
    fraisDossierMinimum
    demandeAccordPourcentageAjoutCapital
    loueurInformationsCreancier{
      raisonSociale
      adresse
      cpVille
      pays
      ics
    }
  }
}
`
export const QUERY_partenaire = gql`
  query($id:ID!){
    partenaire(id:$id){
        ...PartenaireFields
      entrepriseAssociee{
        ...EntrepriseFields
      }
      usersAssocies{
        ...UserFields
      }
    }
  }${ENTREPRISE_FIELDS}
  ${PARTENAIRE_FIELDS}
  ${USER_FIELDS}
`;

export const QUERY_partenaires = gql`
  query($pagination: PaginationObject){
    partenaires(pagination: $pagination){
      ...PartenaireFields
      entrepriseAssociee{
        ...EntrepriseFields
      }
      usersAssocies{
        ...UserFields
      }
    }
    partenairesCount(pagination: $pagination)
  }${ENTREPRISE_FIELDS}
  ${PARTENAIRE_FIELDS}
  ${USER_FIELDS}
`;

export const QUERY_partenairesCount = gql`
query($pagination: PaginationObject){
  partenairesCount(pagination:$pagination)
}
`;

