<template>
  <ListLayout
    ref="list"
    :headers="headers"
    :title="title"
    :noFilter="noFilter"
    @fetch="init"
    @showMultipleActions="showMultipleActions = $event"
  >
    <template v-slot:multipleActions v-if="multiple">
      <FactureFournisseurActions
        :multiple="true"
        :withView="false"
        :object="$refs.list ? $refs.list.itemsSelected : []"
        size="xl"
        v-show="showMultipleActions"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
    <template v-slot:customFilters>
      <ClientSelector :addAction="null" v-model="customFilters.client" />
      <FournisseurSelector
        :addAction="null"
        v-model="customFilters.fournisseur"
      />
    </template>
    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <template v-slot:[`item.object`]="{ item }">
      <FactureFournisseurObject :factureFournisseurProp="item" size="xs" />
    </template>
    <template v-slot:[`item.dateCreation`]="{ item }">
      {{ item.dateCreation | shortDateFR }}
    </template>
    <template v-slot:[`item.client`]="{ item }">
      <ClientObject
        :clientID="item.client.id"
        size="xs"
        action="popup"
        v-if="item.client.id"
      />
    </template>
    <template v-slot:[`item.fournisseur`]="{ item }">
      <FournisseurObject
        :fournisseurID="item.fournisseur.id"
        size="xs"
        action="popup"
        v-if="item.fournisseur"
      />
    </template>
    <template v-slot:[`item.montantHT`]="{ item }">
      <MoneyLayout :montant="item.montantHT" />
    </template>
    <template v-slot:[`item.montantTVA`]="{ item }">
      <MoneyLayout :montant="item.montantTVA" />
    </template>
    <template v-slot:[`item.devisAssocie`]="{ item }">
        <DevisFournisseurObject
          v-if="item.devisAssocie && item.devisAssocie.id"
          size="xs"
          :devisFournisseurID="item.devisAssocie.id"
        />
      </template>
    <template v-slot:[`item.actions`]="{ item }">
      <FactureFournisseurActions
        :object="item"
        size="xs"
        @fetch="init"
        @loading="$refs.list.loading = true"
      />
    </template>
  </ListLayout>
</template>

<script>
import { QUERY_factureFournisseurs } from "./graphql/queries";

import ClientObject from "./ClientObject.vue";
import ClientSelector from "./ClientSelector.vue";
import DevisFournisseurObject from "./DevisFournisseurObject.vue";
import FactureFournisseurActions from "./FactureFournisseurActions.vue";
import FactureFournisseurObject from "./FactureFournisseurObject.vue";
import FournisseurObject from "./FournisseurObject.vue";
import FournisseurSelector from "./FournisseurSelector.vue";
import ListLayout from "@/components/COMPONENTS-skeleton/Layouts/ListLayout.vue";
import MoneyLayout from "@/components/COMPONENTS-skeleton/Layouts/MoneyLayout.vue";

export default {
  name: "FactureFournisseursList",

  components: {
    ClientObject,
    ClientSelector,
    DevisFournisseurObject,
    FactureFournisseurActions,
    FactureFournisseurObject,
    FournisseurObject,
    FournisseurSelector,
    ListLayout,
    MoneyLayout,
  },

  props: {
    customSearch: {
      type: Array,
      default() {
        return undefined;
      },
    },
    multiple: { type: Boolean, default: true },
    noFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default: "Liste des factures",
    },
  },

  data: () => ({
    customFilters: {
      client: null,
      fournisseur: null,
    },
    headers: [
      { text: "", value: "object", sortable: false },
      { text: "Numéro de facture", value: "numeroFacture" },
      { text: "Date de création", value: "dateCreation" },
      { text: "Client", value: "client", sortable: false },
      { text: "Fournisseur", value: "fournisseur", sortable: false },
      { text: "Montant HT", value: "montantHT" },
      { text: "Montant TVA", value: "montantTVA" },
      { text: "Devis attaché", value: "devisAssocie", sortable: false },
      { text: "Fichiers", value: "fichiers", sortable: false },
      { text: "Commentaires", value: "commentaires", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    showMultipleActions: false,
  }),

  methods: {
    fetchFactureFournisseurs() {
       
      this.$refs.list.loading = true;
      let pagination = JSON.parse(JSON.stringify(this.$refs.list.pagination));
      if (this.customSearch) {
        this.customSearch.forEach((element) => {
          pagination.filter.customSearch.push(element);
        });
      }
      if (this.customFilters.client) {
        pagination.filter.customSearch.push({
          client: this.customFilters.client,
        });
      }
      if (this.customFilters.fournisseur) {
        pagination.filter.customSearch.push({
          fournisseur: this.customFilters.fournisseur,
        });
      }

      this.$refs.list.loading = true;
      this.$apollo
        .query({
          query: QUERY_factureFournisseurs,
          variables: {
            pagination: pagination,
          },
          fetchPolicy: "no-cache",
        })
        .then((__) => {
           
          const data = __.data;
          this.$refs.list.items = data.factureFournisseurs;
          this.$refs.list.itemsCount = data.factureFournisseursCount;
          this.$refs.list.loading = false;
           
        });
    },
    init() {
      this.fetchFactureFournisseurs();
    },
  },
  watch: {
    customFilters: {
      deep: true,
      handler() {
        this.$refs.list.currentPage = 1;
        this.init();
      },
    },
  },
};
</script>