<template>
  <ViewLayout :tabs="tabs">
    <template v-slot:parametres>
      <FormLayout icon="mdi-cog" @modifier="modifier" @reinitialiser="init" titre="Paramètres métier" :item="input">
        <TitleLayout titre="Affaires & Contrats - Paramètres financiers" />
        <v-row>
          <v-col>
            <v-text-field v-model.number="input.fraisDossierPourcentage" type="number"
              label="Pourcentage du montant à financer, représentant les frais de dossier" suffix="%"
              prepend-icon="mdi-percent" />
          </v-col>
          <v-col>
            <v-text-field v-model.number="input.fraisDossierMinimum" type="number"
              label="Montant minimum des frais de dossier" suffix="€" prepend-icon="mdi-cash" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select v-model="input.affaireFinancementDefaultIsBeginning" :items="[
              { text: 'Paiement en début de période', value: true },
              { text: 'Paiement en fin de période', value: false },
            ]" label="Mode de paiement par défaut" prepend-icon="mdi-timeline-help" />
          </v-col>
          <v-col>
            <v-select v-model="input.affaireFinancementDefaultCf" :items="
              input.affaireFinancementDefaultIsBeginning
                ? [
                  {
                    text: 'Loyers mensuel optimisés',
                    value: 'mensuelOpti',
                  },
                  {
                    text: 'Loyers trimestriels optimisés',
                    value: 'trimestrielOpti',
                  },
                  {
                    text: 'Loyers semetriels optimisés',
                    value: 'semestrielOpti',
                  },
                  {
                    text: 'Loyers annuels optimisés',
                    value: 'annuelOpti',
                  },
                  { text: 'Loyers mensuels', value: '12' },
                  { text: 'Loyers trimestiels', value: '4' },
                  { text: 'Loyers semestriels', value: '2' },
                  { text: 'Loyers annuels', value: '1' },
                ]
                : [
                  { text: 'Loyers mensuels', value: '12' },
                  { text: 'Loyers trimestiels', value: '4' },
                  { text: 'Loyers semestriels', value: '2' },
                  { text: 'Loyers annuels', value: '1' },
                ]
            " label="Périodicité par défaut" prepend-icon="mdi-timeline-help" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="input.affaireFinancementDefaultRate" type="number"
              label="Taux de financement par défaut" suffix="%" prepend-icon="mdi-percent" />
          </v-col>
          <v-col>
            <v-text-field v-model.number="input.affaireFinancementDefaultFV" type="number"
              label="Valeur future par défaut" suffix="€" prepend-icon="mdi-cash" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model.number="input.affaireFinancementDefaultNpPrincipales" type="number"
              label="Nombre de périodes principales par défaut" suffix="période(s)" prepend-icon="mdi-calendar-range" />
          </v-col>
          <v-col>
            <v-text-field v-model.number="input.affaireFinancementDefaultNpComplementaires" type="number"
              label="Nombre de périodes complémentaires par défaut" suffix="période(s)"
              prepend-icon="mdi-calendar-range" />
          </v-col>
        </v-row>
        <TitleLayout titre="Refinancement - Demande accord" />
        <v-row>
          <v-col>
            <v-text-field v-model.number="input.demandeAccordPourcentageAjoutCapital" type="number"
              label="Pourcentage du capital de l'affaire à ajouter lors de la reprise de données de l'affaire"
              suffix="%" prepend-icon="mdi-percent" />
          </v-col>
          <v-col></v-col>
        </v-row>
        <TitleLayout titre="Loueur - Informations Créancier"
          sousTitre="Utilisées dans les autorisations de prélèvement" />
        <template v-if="input.loueurInformationsCreancier">
          <v-row>
            <v-col>
              <v-text-field v-model="input.loueurInformationsCreancier.raisonSociale" label="Raison sociale" />
            </v-col>
            <v-col>
              <v-text-field prepend-icon="mdi-office-building-marker"
                v-model="input.loueurInformationsCreancier.adresse" label="Adresse" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field prepend-icon="mdi-office-building-marker"
                v-model="input.loueurInformationsCreancier.cpVille" label="Code Postal - Ville" />
            </v-col>
            <v-col>
              <v-text-field prepend-icon="mdi-flag" v-model="input.loueurInformationsCreancier.pays" label="Pays" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field prepend-icon="mdi-contain" v-model="input.loueurInformationsCreancier.ics"
                v-mask="'XXXX XXXX XXX XX'" label="Identifiant Créancier SEPA (ICS)" />
            </v-col>
          </v-row>
        </template>
      </FormLayout>
    </template>

    <template v-slot:templates>
      <v-alert text color="info">
        <div>
          Ajoutez ici vos templates au format word (.docx).<br />
          Ces derniers sont ensuite disponible dans l'applicatifs dans les
          fabriques à fichiers.
        </div>
      </v-alert>
      <TemplatesList :items="templatesAvailable" typeParent="ProjetSateLeaseOrganisation"
        :idParent="projetSateLeaseOrganisationParametres.id" />
    </template>
    <template v-slot:mails>
      <v-alert text color="info">
        <div>Editez ici vos templates d'email servant aux notifications</div>
      </v-alert>
      <EmailTemplatesList :items="emailTemplatesAvailable" typeParent="ProjetSateLeaseOrganisation"
        :idParent="projetSateLeaseOrganisationParametres.id" />
    </template>
    <template v-slot:dataExtract>
      <EtablissementSelector 
        v-model="etbToExtract"
      />
      <v-btn
        color="primary"
        @click="extractAndDownloadAssets"
      >Extraire les assets</v-btn>
    </template>
  </ViewLayout>
</template>

<script>
import { MUTATION_projetSateLeaseOrganisationParametresUpdate } from "./graphql/mutations";
import { QUERY_projetSateLeaseOrganisationParametres, QUERY_assetsInfosFromEtb } from "./graphql/queries";
import { QUERY_etablissement } from "@/components/COMPONENTS-annuaire/graphql/queries";

import EtablissementSelector from "@/components/COMPONENTS-annuaire/EtablissementSelector.vue";
import EmailTemplatesList from "@/components/COMPONENTS-envoi-notifications/EmailTemplatesList.vue";
import FormLayout from "@/components/COMPONENTS-skeleton/Layouts/FormLayout.vue";
import TemplatesList from "@/components/COMPONENTS-fichiers-stockages/TemplatesList";
import TitleLayout from "@/components/COMPONENTS-skeleton/Layouts/TitleLayout.vue";
import ViewLayout from "@/components/COMPONENTS-skeleton/Layouts/ViewLayout.vue";

import exportFromJSON from 'export-from-json'

export default {
  name: "ProjetSateLeaseOrganisationParametres",

  components: {
    EmailTemplatesList,
    EtablissementSelector,
    FormLayout,
    TemplatesList,
    TitleLayout,
    ViewLayout,
  },

  data: () => ({
    emailTemplatesAvailable: [
      {
        type: "Affaire",
        categories: [
          { value: "Envoi Offres Locatives", acl: "USER" },
          { value: "Envoi test", acl: "USER" },
        ],
      },
    ],
    input: {},

    projetSateLeaseOrganisationParametres: {
      id: "",
      contratLocationSignataireLoueur: {},
    },

    tabs: [
      { icon: "mdi-cog", displayName: "Paramètres", name: "parametres" },
      { icon: "mdi-cog", displayName: "Templates", name: "templates" },
      { icon: "mdi-cog", displayName: "Extraction de données", name: "dataExtract" },
      // {
      //   icon: "mdi-email-newsletter",
      //   displayName: "Mails",
      //   name: "mails"
      // },
    ],

    templatesAvailable: [
      {
        type: "Affaire",
        categories: [{ value: "Offres Locatives", acl: "USER" }],
      },
      {
        type: "ContratLocation",
        label: "Contrat de location",
        categories: [
          {
            value: "Conditions particulieres de location",
            acl: "USER",
          },
          {
            value: "Conditions generales de location",
            acl: "USER",
          },
          {
            value: "Annexe 1 - Liste des equipements",
            acl: "USER",
          },
          {
            value: "Annexe 2 - Repartition des loyers",
            acl: "USER",
          },
          {
            value: "Proces verbal de reception et mise en service de materiel",
            acl: "USER",
          },
          {
            value: "Mandat SEPA",
            acl: "USER",
            toSplit: true,
          },
        ],
      },
      {
        type: "Bailleur",
        categories: [
          {
            value: "Test",
            label: "Test Label",
            acl: "USER",
          },
        ],
      },
    ],
    etbToExtract: null
  }),

  mounted: function () {
    this.init();
  },

  methods: {
    async extractAndDownloadAssets() {
      console.log(this.etbToExtract)
      if(this.etbToExtract) {
        let resEtb = await this.$apollo.query({ query: QUERY_etablissement, variables: { id: this.etbToExtract } });
        console.log(resEtb)
        this.$apollo
          .query({
            query: QUERY_assetsInfosFromEtb,
            variables: {
              idEtb: this.etbToExtract
            }
          })
          .then(__ => {
            const fileName 
              = `${resEtb.data.etablissement.libelle}_${resEtb.data.etablissement.adresse.libelleCommune}_${resEtb.data.etablissement.siret}_assets`;

            exportFromJSON({ data: __.data.assetsInfosFromEtb, fileName, exportType: 'xls' })
          })
      }
    },

    init() {
      this.$apollo
        .query({
          query: QUERY_projetSateLeaseOrganisationParametres,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          let projetSateLeaseOrganisationParametres =
            data.data.projetSateLeaseOrganisationParametres;
          // this.input = JSON.parse(
          //   JSON.stringify(projetSateLeaseOrganisationParametres)
          // );
          this.input = this.inputConverter(
            projetSateLeaseOrganisationParametres
          );
        });
    },

    inputConverter(arg) {
      const obj = JSON.parse(JSON.stringify(arg));

      let input = {
        affaireFinancementDefaultRate: obj.affaireFinancementDefaultRate,
        affaireFinancementDefaultNpPrincipales:
          obj.affaireFinancementDefaultNpPrincipales,
        affaireFinancementDefaultNpComplementaires:
          obj.affaireFinancementDefaultNpComplementaires,
        affaireFinancementDefaultFV: obj.affaireFinancementDefaultFV,
        affaireFinancementDefaultIsBeginning:
          obj.affaireFinancementDefaultIsBeginning,
        affaireFinancementDefaultCf: obj.affaireFinancementDefaultCf,
        contratLocationSignataireLoueur: obj.contratLocationSignataireLoueur,
        fraisDossierPourcentage: obj.fraisDossierPourcentage,
        fraisDossierMinimum: obj.fraisDossierMinimum,
        demandeAccordPourcentageAjoutCapital:
          obj.demandeAccordPourcentageAjoutCapital,
        loueurInformationsCreancier: {
          raisonSociale: obj.loueurInformationsCreancier.raisonSociale,
          adresse: obj.loueurInformationsCreancier.adresse,
          cpVille: obj.loueurInformationsCreancier.cpVille,
          pays: obj.loueurInformationsCreancier.pays,
          ics: obj.loueurInformationsCreancier.ics,
        },
      };

      obj.id != undefined ? (input.id = obj.id) : null;

      return input;
    },

    modifier() {
      let toSend = this.inputConverter(this.input);
      this.$apollo.mutate({
        mutation: MUTATION_projetSateLeaseOrganisationParametresUpdate,
        variables: {
          input: toSend,
        },
        update: (
          store,
          { data: { projetSateLeaseOrganisationParametresUpdate } }
        ) => {
          this.$emit(
            "projetSateLeaseOrganisationParametresUpdateReturn",
            projetSateLeaseOrganisationParametresUpdate
          );
          this.$store.commit("addAlert", {
            type: "info",
            text: "Le paramètrage a été modifié",
          });
          this.$root.$emit("projet-satelease--update-OrganisationParametres");
          this.$root.$emit("skeleton--close");
        },
      });
    },
  },
};
</script>
