import gql from 'graphql-tag';

export const ADRESSE_POSTALE_FIELDS = gql`
  fragment AdressePostaleFields on AdressePostale{
    numeroVoie
    libelleVoie
    codePostal
    libelleCommune
    cedex
    longitude
    latitude
  }
`;

export const ANNUAIREAPPLICATIONPARAMETRES_FIELDS = gql`
  fragment AnnuaireApplicationParametresFields on AnnuaireApplicationParametres {
    id
    suiviJetons {
      jetonsRestants
      jetonsTotal
      jetonsPayAsYouGo
    }
  } 
`;

export const ENTREPRISE_FIELDS = gql`
  fragment EntrepriseFields on Entreprise{
    id
    isActif
    dateCreation
    siren
    isInternationale
    raisonSociale
    nomCommercial
    numeroTvaIntra
    nbEtablissements
    capital
    formeJuridique
    villeRCS
    logo{
      id
    }  
    meta
    holding {
      id
    }
    codeNaf
  }
`;

export const ENSEIGNE_FIELDS = gql`
  fragment EnseigneFields on Enseigne{
    id
    isActif
    dateCreation
    nom
    description
    logo{
      id
    }
  }
`;

export const ETABLISSEMENT_FIELDS = gql`
  fragment EtablissementFields on Etablissement{
    id
    dateCreation
    libelle
    siret
    isSiege
    adresse {
      ...AdressePostaleFields
    }
    entreprise{
      ...EntrepriseFields
      logo{
        id
      }
    }
    isActif
    meta
  }${ENTREPRISE_FIELDS}${ADRESSE_POSTALE_FIELDS}
`;

export const PERSONNE_FIELDS = gql`
  fragment PersonneFields on Personne {
    id
    nom
    prenom
    dateCreation
    note
    isActif
    numerosTelephone{
      indicatif{
        name
        dialCode
        flag
      }
      numero
      note
      demarchage
    }
    adressesMails{
      adresse
      newsletter
    }
    avatar{
      id
    }
    dateNaissance
  }
`;