var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormLayout',{attrs:{"icon":"mdi-card-account-mail-outline","typeItem":"une personne","item":_vm.input,"disabled":_vm.input.nom && _vm.input.prenom ? false : true},on:{"ajouter":_vm.ajouter,"modifier":_vm.modifier,"reinitialiser":_vm.init}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12","align":"center"}},[_c('FichierLogo',{attrs:{"text":_vm.input.prenom + ' ' + _vm.input.nom,"size":120},model:{value:(_vm.input.avatar),callback:function ($$v) {_vm.$set(_vm.input, "avatar", $$v)},expression:"input.avatar"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"given-name","error-messages":_vm.formsErrors.personnePrenom,"label":"Prénom","required":""},model:{value:(_vm.input.prenom),callback:function ($$v) {_vm.$set(_vm.input, "prenom", $$v)},expression:"input.prenom"}}),_c('v-text-field',{attrs:{"name":"family-name","error-messages":_vm.formsErrors.personneNom,"label":"Nom de famille","required":""},model:{value:(_vm.input.nom),callback:function ($$v) {_vm.$set(_vm.input, "nom", $$v)},expression:"input.nom"}}),_c('DateInput',{attrs:{"label":"Date de naissance","max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)},model:{value:(_vm.input.dateNaissance),callback:function ($$v) {_vm.$set(_vm.input, "dateNaissance", $$v)},expression:"input.dateNaissance"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.input.adressesMails && _vm.input.adressesMails[0])?[_c('v-divider'),_c('v-subheader',[_vm._v("Adresses e-mails :")])]:_vm._e(),_vm._l((_vm.input.adressesMails),function(mail,index){return [_c('v-row',{key:("mail-" + index),staticClass:"align-item"},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"name":"email","error-messages":_vm.formsErrors.personneMail
                ? _vm.formsErrors.personneMail[index]
                  ? _vm.formsErrors.personneMail[index]
                  : ''
                : '',"label":"Adresse de courrier électronique","prepend-icon":"mdi-email-outline"},model:{value:(mail.adresse),callback:function ($$v) {_vm.$set(mail, "adresse", $$v)},expression:"mail.adresse"}})],1),_c('v-col',{attrs:{"cols":"2","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","color":mail.newsletter ? 'primary' : 'none'},on:{"click":function($event){{
                      mail.newsletter = !mail.newsletter;
                    }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-newspaper-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Recoit la newsletter "),_c('br'),_vm._v("sur cette adresse")])])],1),_c('v-col',{attrs:{"cols":"2","align":"center"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){return _vm.removeEmailField(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]})],2),_c('v-col',[(_vm.input.numerosTelephone && _vm.input.numerosTelephone[0])?[_c('v-divider'),_c('v-subheader',[_vm._v("Numéros de téléphone :")])]:_vm._e(),_vm._l((_vm.input.numerosTelephone),function(phone,index){return [_c('v-row',{key:("phone-" + index),attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"5","md":"3"}},[_c('v-select',{attrs:{"name":"tel-country-code","hint":("" + (phone.indicatif.name)),"items":_vm.phoneIndicators,"item-text":function (item) { return item.name + ' : ' + item.dialCode; },"prepend-icon":"mdi-phone-outline","persistent-hint":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.flag)+" "+_vm._s(item.dialCode)+" ")]}}],null,true),model:{value:(phone.indicatif),callback:function ($$v) {_vm.$set(phone, "indicatif", $$v)},expression:"phone.indicatif"}})],1),_c('v-col',{attrs:{"col":"1"}},[_c('v-text-field',{attrs:{"name":"tel-national","error-messages":_vm.formsErrors.personneNumeroTelephone
                ? _vm.formsErrors.personneNumeroTelephone[index]
                  ? _vm.formsErrors.personneNumeroTelephone[index]
                  : ''
                : '',"label":"Numéro de téléphone"},model:{value:(phone.numero),callback:function ($$v) {_vm.$set(phone, "numero", $$v)},expression:"phone.numero"}})],1),_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","color":phone.demarchage ? 'primary' : 'none'},on:{"click":function($event){{
                      phone.demarchage = !phone.demarchage;
                    }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-headset")])],1)]}}],null,true)},[_c('span',[_vm._v("Accepte le démarchage téléphonique / SMS")])])],1),_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){return _vm.removePhoneField(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]})],2)],1),(_vm.input.fonctions && _vm.input.fonctions[0])?_c('div',[_c('v-divider'),_c('v-subheader',{staticClass:"mb-3"},[_vm._v("Fonctions occupées :")]),_vm._l((_vm.input.fonctions),function(fonction,index){return [_c('v-row',{key:("fonction-" + index)},[_c('v-col',{attrs:{"cols":"6"}},[_c('PersonneFonctionSelector',{attrs:{"error-messages":_vm.formsErrors.personneFonctionFonction
              ? _vm.formsErrors.personneFonctionFonction[index]
                ? _vm.formsErrors.personneFonctionFonction[index]
                : ''
              : ''},model:{value:(fonction.fonction),callback:function ($$v) {_vm.$set(fonction, "fonction", $$v)},expression:"fonction.fonction"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('EntrepriseSelector',{model:{value:(fonction.entreprise),callback:function ($$v) {_vm.$set(fonction, "entreprise", $$v)},expression:"fonction.entreprise"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('EtablissementSelector',{attrs:{"disabled":fonction.entreprise ? false : true,"entreprise":fonction.entreprise && fonction.entreprise.id
              ? fonction.entreprise.id
              : fonction.entreprise},model:{value:(fonction.etablissement),callback:function ($$v) {_vm.$set(fonction, "etablissement", $$v)},expression:"fonction.etablissement"}})],1),_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"icon":"","color":fonction.pouvoirSignature ? 'primary' : 'none'},on:{"click":function($event){{
                    fonction.pouvoirSignature = !fonction.pouvoirSignature;
                  }}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fountain-pen-tip")])],1)]}}],null,true)},[_c('span',[_vm._v("A le pouvoir de signature")])])],1),_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"icon":""},on:{"click":function($event){return _vm.removeFonctionField(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]})],2):_vm._e(),_c('v-card-actions',{staticClass:"pl-0"}),_c('v-row',{staticClass:"ma-4",attrs:{"justify":"center","no-gutters":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info"}},'v-btn',attrs,false),on),[_vm._v(" Ajouter plus ")])]}}])},[_c('v-list',{staticClass:"d-flex flex-column"},[_c('v-list-item',{on:{"click":_vm.addEmailField}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-email-outline")]),_vm._v(" Adresse email ")],1)],1),_c('v-list-item',{on:{"click":_vm.addPhoneField}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-phone-outline")]),_vm._v(" Numéro de téléphone ")],1)],1),_c('v-list-item',{on:{"click":_vm.addFonctionField}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chess-pawn")]),_vm._v(" Fonction occupée ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }